<template>
  <!--begin::Modal - New Target-->
  <div class="modal fade" id="modal_print" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-850px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <div id="printableArea" class="home">
            <div class="text-center">
              <img src="logo.png" style="width: 150px" /><br />
              <h3>คำร้องทั่วไป</h3>
            </div>
            <div class="text-end">
              <p>{{ dateThai(appeals.created_at) }}</p>
            </div>
            <div class="text-start">
              <p>เรื่อง : {{ appeals.title }} {{ appeals.sub_title }}</p>
              <p>เรียน นายกเทศมนตรีเมืองแม่ฮ่องสอน</p>
            </div>

            <div class="text-start">
              <p class="van">
                ข้าพเจ้า &nbsp;&nbsp;
                <span v-if="appeals.user.firstname">
                  {{ appeals.user.firstname }} &nbsp; {{ appeals.user.lastname }}
                </span>
                <span v-else> ไม่แสดงตัวตน</span> &nbsp;&nbsp; อายุ.................ปี
                อยู่บ้านเลขที่ &nbsp;&nbsp; {{ appeals.user.numberhome }} &nbsp;&nbsp;
                <span v-if="appeals.user.road"
                  >ถนน {{ appeals.user.road }} &nbsp;&nbsp;</span
                >
                &nbsp;&nbsp;
                <span v-if="appeals.user.alleyway"
                  >{{ appeals.user.alleyway }} &nbsp;&nbsp;</span
                >
              </p>

              <p>
                ตำบล &nbsp;&nbsp; {{ appeals.user.district }} &nbsp;&nbsp; อำเภอ
                {{ appeals.user.amphoe }} &nbsp;&nbsp; จังหวัด
                {{ appeals.user.province }} &nbsp;&nbsp; เบอร์โทรศัพท์ &nbsp;&nbsp;{{
                  appeals.user.tel
                }}
              </p>

              <p>
                อาศัยอยู่ในชุมชน &nbsp;&nbsp; {{ appeals.user.district }} &nbsp;&nbsp;
                มีความประสงค์ให้{{ Appname }}ดำเนินการเรื่องต่าง ๆ ดังนี้
              </p>
              <p class="van">{{ appeals.detail }}</p>
              <br />
              <p class="van">จึงเรียนมาเพื่อโปรดพิจารณา</p>
            </div>
            <br />
            <div class="text-center font20">
              <p>ขอแสดงความนับถือ</p>
              <p>
                (ลงชื่อ)<span style="border-bottom-style: dotted; padding: 0px 20px"
                  >{{ appeals.user.firstname }}&nbsp;&nbsp;{{
                    appeals.user.lastname
                  }}</span
                >ผู้ยื่นคำร้อง
              </p>
              <p>
                (<span style="border-bottom-style: dotted; padding: 0px 20px"
                  >{{ appeals.user.firstname }}&nbsp;&nbsp;{{
                    appeals.user.lastname
                  }}</span
                >)
              </p>
              <p>ตำแหน่ง..........................................................</p>
            </div>
          </div>

          <!--begin::Actions-->
          <div class="text-end">
            <button type="button" @click="Print" class="btn btn-primary">
              ยืนยัน พิมพ์
            </button>
          </div>
          <!--end::Actions-->

          <!--end::Col-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script>
import { defineComponent, ref } from "vue";
import moment from "moment";
import printJS from "print-js";
export default defineComponent({
  name: "print",
  props: ["appeals"],
  setup() {
    const show = ref(false);
    const Appname = ref(process.env.VUE_APP_NAME);

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("วันที่ D MMMM " + year);
    }
    const Print = () => {
      printJS({
        printable: "printableArea",
        type: "html",
        style:
          ".text-center{text-align: center;} .text-start{text-align: left;} .text-end{text-align: right;}",
        font_size: "12pt",
        font: "Sarabun sans-serif",
      });
    };

    return { Print, show, dateThai, Appname };
  },
});
</script>

<style></style>

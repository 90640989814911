<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">ส่งเรื่อง</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body p-9">
      <Form
        @submit="onSubmitTicket"
        :validation-schema="ticket"
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
      >
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold required text-muted">หัวข้อเรื่อง</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field
              name="title"
              class="form-control h-auto form-control-solid py-4 px-8"
              as="select"
              v-model="title"
            >
              <option value="" disabled selected>กรุณากรอกเลือก</option>
              <option v-for="title in Titles" :key="title.name" :value="title.name">
                {{ title.name }}
              </option>

              <!--:selected="value && value.includes(role.id)"-->
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="title" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->

        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold required text-muted">เรื่อง</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field
              name="sub_title"
              class="form-control h-auto form-control-solid py-4 px-8"
              as="select"
            >
              <option value="" disabled selected>กรุณากรอกเลือก</option>
              <option
                v-for="sub_title in SubTitles"
                :key="sub_title.name"
                :value="sub_title.name"
              >
                {{ sub_title.name }}
              </option>

              <!--:selected="value && value.includes(role.id)"-->
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="sub_title" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->

        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label"> ส่งเรื่อง </span>
              <span class="indicator-progress">
                กรุณารอสักครู่ ...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import useSweetalert from "@/core/helpers/sweetalert2";
import useAppeals from "@/core/services/api/appeal/appeals";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  name: "appeal-show-manage-send-to",
  props: ["appeals"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props, { emit }) {
    const { Sconfirm, SToast, SconfirmNc } = useSweetalert();
    const { getSubTitle, sendChangeTitle } = useAppeals();

    const ticket = Yup.object().shape({
      title: Yup.string().required("กรุณาเลือก หัวข้อเรื่อง"),
      sub_title: Yup.string().required("กรุณาเลือก เรื่อง"),
    });
    const titles = ref([]);
    const title = ref("");
    const sub_titles = ref([]);

    const id = computed(() => {
      return props.appeals.id;
    });

    onMounted(() => {
      getSubTitle()
        .then((res) => {
          titles.value = res.data.titles;
          sub_titles.value = res.data.sub_titles;
        })
        .catch(() => {
          SconfirmNc("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", "error", "ลองอีกครั้ง !");
        });
    });

    const Titles = computed(() => {
      let dd = titles.value.filter((item) => item.name !== "เรื่องอื่น ๆ");

      return dd;
    });

    const SubTitles = computed(() => {
      let dd = sub_titles.value.filter((item) => item.title === title.value);

      return dd;
    });

    const onSubmitTicket = async (values) => {
      Sconfirm("ยืนยัน การส่งเรื่อง", "question").then(async (result) => {
        if (result.isConfirmed) {
          emit("Loading", true);
          values.id = props.appeals.id;
          await sendChangeTitle(values)
            .then(() => {
              SToast("success", "การส่งเรื่อง  สำเร็จ");
              emit("getAticket");
            })
            .catch(() => {
              emit("Loading", false);
              SconfirmNc("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", "error", "ลองอีกครั้ง !");
            });
        }
      });
    };

    return {
      id,
      ticket,
      onSubmitTicket,
      Titles,
      title,
      SubTitles,
    };
  },
});
</script>

<style scoped>
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
.btn-new-icon {
  width: 250px;
  height: 250px;
  background-color: #f5f8fa;
}
.icon-ima {
  width: 30px;
  margin-left: 5px;
}
.btn-outline-secondary {
  border: 1px solid var(--color) !important;
}
.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
.addimage {
  border: 1px solid var(--color) !important;
}
</style>

<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">สรุป</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >ระยะเวลาดำเนินการทั้งหมด</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ appeals.period }} วัน</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">คะแนนความพึงพอใจ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark" v-if="rating">
            <star-rating
              class="mb-2"
              v-bind:star-size="30"
              v-bind:show-rating="false"
              :animate="true"
              v-model:rating="rating"
              :active-border-color="['#F6546A', '#a8c3c0']"
              :border-width="1"
              :rounded-corners="true"
              :active-on-click="true"
              :read-only="true"
            ></star-rating>
          </span>
         <span v-else class="fw-bolder fs-6 text-dark">-</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">ขอเสนอแนะ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark" v-if="appeals.comment">{{
            appeals.comment
          }}</span>
          <span v-else class="fw-bolder fs-6 text-dark">-</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
</template>

<script>
import StarRating from "vue-star-rating";
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "appeal-show-report",
  props: ["appeals"],
  components: {
    StarRating,
  },
  setup(props) {
    const rating = computed(() => {
      return props.appeals.score;
    });

    return { rating };
  },
});
</script>

<style scoped></style>

<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <Navbar
          v-if="appeals.user"
          :profile_line="profile_line"
          :appeals="appeals"
          @getAticket="getApp"
        ></Navbar>
        <Detail
          v-if="appeals.user"
          :appeals="appeals"
          @delateAppeal="delateAppeal"
        ></Detail>
        <Timeline
          v-if="appeals.status !== 'รอตรวจสอบ'"
          :appeals="appeals"
        ></Timeline>
        <MenageSendTo
          v-if="can('appeal-sendto', 'all')"
          :appeals="appeals"
          @getAticket="getApp"
          @Loading="Loading"
        ></MenageSendTo>
        <Menage
          v-if="
            appeals.status !== 'เสร็จสิ้น' && appeals.status !== 'รอตรวจสอบ'
          " 
          :appeals="appeals"
          @getAticket="getApp"
          @Loading="Loading"
        ></Menage>
        <Report
          v-if="appeals.status === 'เสร็จสิ้น'"
          :appeals="appeals"
        ></Report>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/appeal/Navbar";
import Detail from "@/components/appeal/Detail";
import Timeline from "@/components/appeal/Timeline";
import Report from "@/components/appeal/Report";
import Menage from "@/components/appeal/Menage";
import MenageSendTo from "@/components/appeal/MenageSendTo";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { checkPage } from "@/core/helpers/checkpage";
import useAppeals from "@/core/services/api/appeal/appeals";
import useSweetalert from "@/core/helpers/sweetalert2";
import { useAbility } from "@casl/vue";
export default defineComponent({
  name: "appeal-show",
  components: {
    Navbar,
    Detail,
    Timeline,
    Menage,
    MenageSendTo,
    Report,
  },
  setup() {
    const { SToast, SconfirmNc } = useSweetalert();
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const { getAppeal, destroyAppeal } = useAppeals();
    const appeals = ref([]);
    const profile_line = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const { can } = useAbility();

    onMounted(() => {
      checkPage("appeal-list");
      setCurrentPageBreadcrumbs("ใบงานที่ " + id.value, ["ใบงานร้องเรียน"]);
      getApp();
    });

    async function getApp() {
      isLoading.value = true;
      await getAppeal(id.value)
        .then((res) => {
          if (res.data.status === "Not Ticket") {
            SToast("error", "ไม่พบ ใบงานนี้");
            router.push({ name: "apps.appeals.appeals.wait" });
          } else if (res.data.status === "Not Auth") {
            SToast("error", "ไม่มีสิทธิ์เข้าถึง ใบงานนี้");
            router.push({ name: "apps.appeals.appeals.wait" });
          }

          appeals.value = res.data.data;
          profile_line.value = res.data.profile_line;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
    }

    function Loading(val) {
      isLoading.value = val;
    }

    async function delateAppeal() {
      isLoading.value = true;
      await destroyAppeal(id.value)
        .then(() => {
          SToast("success", "ลบ ใบงาน สำเร็จ");
          router.push({ name: "apps.appeals.appeals.wait" });
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
    }

    return {
      can,
      id,
      isLoading,
      color,
      appeals,
      profile_line,
      getApp,
      delateAppeal,
      Loading,
    };
  },
});
</script>
<style scoped></style>

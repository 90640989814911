<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">รายละเอียด</h3>
      </div>
      <!--end::Card title-->
      <div style="float: right" class="align-self-center">
        <button
          @click="delateAppeal"
          v-if=" can('appeal-delete', 'all')"
          type="button"
          class="btn btn btn-danger m-2"
        >
          ลบ <i class="bi bi-trash"></i>
        </button>

        <button
          v-if="appeals.status !== 'รอตรวจสอบ'"
          type="button"
          class="btn btn btn-success m-2"
          data-bs-toggle="modal"
          data-bs-target="#modal_print"
        >
          พิมพ์ <i class="bi bi-printer"></i>
        </button>
      </div>
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">ใบงานที่</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ appeals.id }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">หัวข้อ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ appeals.title }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">เรื่อง</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ appeals.sub_title }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">รายละเอียด</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ appeals.detail }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >เบอร์โทรศัพท์
          <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            data-bs-toggle="tooltip"
            title="เบอร์โทรศัพท์ติดต่อผู้แจ้ง"
          ></i
        ></label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8 d-flex align-items-center" v-if="appeals.user.tel">
          <span class="fw-bold fs-6">{{ appeals.user.tel }}</span>
          <span class="badge badge-success">Verified</span>
        </div>
        <div class="col-lg-8 d-flex align-items-center" v-else>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">วันที่แจ้ง</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bold fs-6">{{ dateThai(appeals.created_at) }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">สถานะ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bold fs-6">{{ appeals.status }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::latest instagram-->
      <div class="mb-8" v-if="appeals.appeal_images.length > 0">
        <!--begin::Section-->
        <div class="m-0">
          <!--begin::Content-->
          <div class="d-flex flex-stack">
            <!--begin::Title-->
            <h3 class="text-black">รูปภาพ</h3>
            <!--end::Title-->
          </div>
          <!--end::Content-->
          <!--begin::Separator-->
          <div
            class="separator separator-dashed border-gray-300 mb-9 mt-5"
          ></div>
          <!--end::Separator-->
        </div>
        <!--end::Section-->
        <!--begin::Row-->
        <div class="row g-10">
          <!--begin::Col-->
          <div
            class="col-4"
            v-for="(appeal_images, key) in appeals.appeal_images"
            :key="key"
          >
            <!--begin::Overlay-->

            <!--begin::Image-->
            <a @click="show(key)" class="d-block">
              <!--begin::Image-->
              <div
                class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
                style="cursor: pointer"
                :style="{ backgroundImage: 'url(' + appeal_images.src + ')' }"
              ></div>
              <!--end::Image-->
            </a>

            <!--end::Image-->
          </div>

          <vue-easy-lightbox
            scrollDisabled
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="appeals.appeal_images"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>

          <!--end::Col-->
        </div>
        <!--begin::Row-->
      </div>
      <!--end::latest instagram-->

      <div class="" v-if="appeals.latitude">
        <!--begin::Section-->
        <div class="m-0">
          <!--begin::Content-->
          <div class="d-flex flex-stack">
            <!--begin::Title-->
            <h3 class="text-black">แผนที่</h3>
            <!--end::Title-->
          </div>
          <!--end::Content-->
          <!--begin::Separator-->
          <div
            class="separator separator-dashed border-gray-300 mb-9 mt-5"
          ></div>
          <!--end::Separator-->
        </div>
        <!--end::Section-->
        <!--begin::Row-->
        <div class="row g-10 row-cols-2 row-cols-lg-5">
          <!--begin::Col-->
          <div class="col">
            กด
            <a
              target="_blank"
              :href="openMap(appeals.latitude, appeals.longitude)"
              class="btn btn-app"
              style="cursor: pointer"
            >
              <img
                src="@/assets/appeal/icon/google_map.png"
                class="icon-map"
                alt="image"
              />
            </a>
            เพื่อดูแผนที่
          </div>
          <!--end::Col-->
        </div>
        <!--begin::Row-->
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
  <Print :appeals="appeals"></Print>
</template>

<script>
import Print from "@/components/appeal/Print.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import moment from "moment";
import { defineComponent, ref } from "vue";
import useSweetalert from "@/core/helpers/sweetalert2";
import { useAbility } from "@casl/vue";
export default defineComponent({
  name: "appeal-show-detail",
  components: { VueEasyLightbox, Print },
  props: ["appeals"],
  setup(props, { emit }) {
    const visible = ref(false);
    const index = ref(0);
    const { Sconfirm } = useSweetalert();
    const { can } = useAbility();
    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", LT");
    }
    function handleHide() {
      visible.value = false;
    }
    function openMap(lat, lng) {
      return `http://maps.google.com/maps?q=${lat},${lng}`;
    }

    function show(key) {
      index.value = key;
      visible.value = true;
    }

    const delateAppeal = () => {
      Sconfirm(`ยืนยันการลบใบงาน ที่ ${props.appeals.id}`, "question").then(
        async (result) => {
          if (result.isConfirmed) {
            emit("delateAppeal");
          }
        }
      );
    };

    return {
      dateThai,
      visible,
      handleHide,
      index,
      show,
      openMap,
      delateAppeal,
      can,
    };
  },
});
</script>

<style scoped>
.vel-img-modal.vel-modal {
  width: 100%;
}
.icon-map {
  width: 50px;
}
</style>

import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useAppeals() {
  const router = useRouter();

  const getAppeal_Wait = async (title,value,page) => {
    return await ApiService.search("appeal_wait",title,value,page);
  };

  const getAppeal_Receive = async (title,value,page) => {
    return await ApiService.search("appeal_receive",title,value,page);
  };

  const getAppeal_Processing = async (title,value,page) => {
    return await ApiService.search("appeal_processing",title,value,page);
  };


  const getAppeal_Finish = async(title,value,page) => {
    return await ApiService.search("appeal_finish",title,value,page);
  };

  const getAppeal = async (id) => {
    return await ApiService.get("appeals/" + id);
  };

  const getSubTitle = async () => {
    return await ApiService.get("sub_title_all");
  };

  const sendChangeTitle = async (data) => {
   return  await ApiService.put("get_a_change_title/" + data.id, data);

  };

  const getAticket = async (data) => {
    await ApiService.put("get_a_ticket/" + data.id, data);
    await router.push({
      name: "apps.appeals.appeals.show",
      params: { id: data.id },
    });
  };

  const ProcessingAticket = async (data) => {
    await ApiService.put("processing_a_ticket/" + data.id, data);
    await router.push({
      name: "apps.appeals.appeals.show",
      params: { id: data.id },
    });
  };

  const FinishAticket = async (data) => {
    await ApiService.put("finish_a_ticket/" + data.id, data);
    await router.push({
      name: "apps.appeals.appeals.show",
      params: { id: data.id },
    });
  };


  const destroyAppeal = async (id) => {
    return await ApiService.delete("appeals/" + id);
  };


  const UpdateAticket = async (data) => {
    return  await ApiService.put("update_a_ticket/" + data.id, data);

  };

  


  return {
    getAppeal,
    getAppeal_Wait,
    getAppeal_Receive,
    getAppeal_Processing,
    getAppeal_Finish,
    getAticket,
    ProcessingAticket,
    FinishAticket,
    destroyAppeal,
    UpdateAticket,
    getSubTitle,
    sendChangeTitle
  };
}

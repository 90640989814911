<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">จัดการ</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9" v-if="appeals.status === 'รับคำร้อง'">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">สถานะ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ appeals.status }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"></label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8" v-if="can('appeal-edit', 'all')">
          <span class="fw-bolder fs-6 text-dark">
            <button
              type="submit"
              class="btn btn-primary align-self-center"
              @click="onSubmitAticket(appeals.id, appeals.title)"
            >
              ดำเนินการแก้ไข
            </button></span
          >
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>

    <!--begin::Card body-->
    <div class="card-body p-9" v-if="appeals.status === 'ดำเนินการ'">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">สถานะ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ appeals.status }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <Form
        @submit="onSubmitTicket"
        :validation-schema="ticket"
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
      >
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold required text-muted"
            >รายละเอียดการแก้ไข</label
          >
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <Field type="hidden" name="id" v-model="id" />
            <Field
              as="textarea"
              rows="6"
              name="detail"
              class="form-control form-control-md form-control-solid"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="detail" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
        <div class="row mb-7">
          <div
            v-if="imageUrl.length !== 0"
            class="imagePreviewWrapper"
            style="padding: 15px 0px"
          >
            <div class="row">
              <div
                style="position: relative"
                class="col-4"
                v-for="(item, index) in imageUrl"
                :key="index"
              >
                <div class="xx" @click="deleteImage(index)">
                  <span>x</span>
                </div>
                <img class="images" :src="item" />
              </div>
            </div>
          </div>
        </div>
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">รูปภาพ</label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="hidden" name="imageUrl" v-model="imageUrl" />

            <input
              type="file"
              id="getFile"
              multiple="multiple"
              accept="image/jpg, image/jpeg, image/png"
              @input="pickFile"
              style="display: none"
            />

            <button type="button" class="btn addimage" @click="selectImage()">
              <img style="width: 50px" src="@/assets/picture.png" /><br />
              แนบรูป
            </button>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->

        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label"> ยืนยัน </span>
              <span class="indicator-progress">
                กรุณารอสักครู่ ...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import moment from "moment";
import useSweetalert from "@/core/helpers/sweetalert2";
import { useAbility } from "@casl/vue";
import useAppeals from "@/core/services/api/appeal/appeals";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  name: "appeal-show-manage",
  props: ["appeals"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props, { emit }) {
    const { Sconfirm, SmessIn, SconfirmNc, SToast } = useSweetalert();
    const { can } = useAbility();
    const { ProcessingAticket, FinishAticket } = useAppeals();
    const imageUrl = ref([]);
    const ticket = Yup.object().shape({
      detail: Yup.string()
        .required("กรุณากรอก รายละเอียดการแก้ไข")
        .label("รายละเอียดการแก้ไข"),
    });

    const id = computed(() => {
      return props.appeals.id;
    });

    const selectImage = () => {
      document.getElementById("getFile").click();
    };
    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };
    const pickFile = async (e) => {
      const files = e.target.files;

      for (var i = 0; i < files.length; i++) {
        imageUrl.value.push(
          await reduce_image_file_size(await image_to_base64(files[i]))
        );
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    const onSubmitTicket = async (values) => {
      Sconfirm("ยืนยัน การปิดงาน", "question").then(async (result) => {
        if (result.isConfirmed) {
          await FinishAticket(values)
            .then(() => {
              SToast("success", "การปิดงาน ใบงาน สำเร็จ");
              emit("getAticket");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                SToast("error", errors.message);
              }
            });
        }
      });
    };

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    const onSubmitAticket = (id, title) => {
      SmessIn(
        `เจ้าหน้าที่ ตรวจสอบใบงานที่ ${id} เรื่อง ${title} ของท่านแล้วอยู่ระหว่างดำเนินการ`
      ).then((res1) => {
        if (res1.isConfirmed) {
          if (res1.value) {
            Sconfirm("ยืนยัน การดำเนินการ", "question").then(async (result) => {
              if (result.isConfirmed) {
                const data = {
                  mess: res1.value,
                  id: id,
                };
                sendAticket(data);
              }
            });
          } else {
            SconfirmNc(
              "กรุณากรอก ข้อความที่แจ้ง...",
              "error",
              "ลองอีกครั้ง !"
            ).then(() => {
              onSubmitAticket(id);
            });
          }
        }
      });
    };

    async function sendAticket(data) {
      await ProcessingAticket(data)
        .then(() => {
          SToast("success", "การดำเนินการ ใบงาน สำเร็จ");
          emit("getAticket");
        })
        .catch((error) => {
          if (error.response) {
            let errors = error.response.data;
            SToast("error", errors.message);
          }
        });
    }

    return {
      dateThai,
      onSubmitAticket,
      can,
      ticket,
      onSubmitTicket,
      selectImage,
      pickFile,
      imageUrl,
      deleteImage,
      id,
    };
  },
});
</script>

<style scoped>
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
.btn-new-icon {
  width: 250px;
  height: 250px;
  background-color: #f5f8fa;
}
.icon-ima {
  width: 30px;
  margin-left: 5px;
}
.btn-outline-secondary {
  border: 1px solid var(--color) !important;
}
.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
.addimage {
  border: 1px solid var(--color) !important;
}
</style>
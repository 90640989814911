<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">ไทม์ไลน์</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9">
      <div class="timeline">
        <!--begin::Timeline-->
        <template v-for="timeline in appeals.timeline" :key="timeline.id">
          <!--begin::Timeline item-->
          <div class="timeline-item" v-if="timeline.status === 'รอตรวจสอบ'">
            <!--begin::Timeline line-->
            <div class="timeline-line w-40px"></div>
            <!--end::Timeline line-->
            <!--begin::Timeline icon-->
            <div class="timeline-icon symbol symbol-circle symbol-40px">
              <div class="symbol-label bg-light">
                <!--begin::Svg Icon | path: icons/duotone/Communication/Thumbtack.svg-->
                <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                  <i style="font-size: 20px" class="bi bi-megaphone"></i>
                </span>
              </div>
            </div>
            <!--end::Timeline icon-->
            <!--begin::Timeline content-->
            <div class="timeline-content mb-10 mt-n2">
              <!--begin::Timeline heading-->
              <div class="overflow-auto pe-3">
                <!--begin::Title-->
                <div class="fs-5 fw-bold mb-2">แจ้งร้องทุกข์</div>

                <!--end::Title-->
                <!--begin::Description-->
                <div class="d-flex align-items-center mt-1 fs-6">
                  <!--begin::Info-->
                  <div class="text-muted me-2 fs-7">
                    {{ dateThai(appeals.created_at) }} โดย :
                    {{ appeals.user.firstname }}
                    {{ appeals.user.lastname }}
                  </div>
                  <!--end::Info-->
                  <!--begin::User-->
                  <div
                    class="symbol symbol-circle symbol-25px"
                    data-bs-toggle="tooltip"
                    data-bs-boundary="window"
                    data-bs-placement="top"
                    title="Alan Nilson"
                  ></div>
                  <!--end::User-->
                </div>
                <!--end::Description-->
              </div>
              <!--end::Timeline heading-->
              <!--begin::Timeline details-->
              <div class="overflow-auto pb-5">
                <!--begin::Record-->
                <div
                  class="
                    d-flex
                    align-items-center
                    border border-dashed border-gray-300
                    rounded
                    min-w-750px
                    px-7
                    py-3
                    mb-5
                  "
                >
                  <!--begin::Title-->
                  <a
                    class="
                      fs-6
                      text-dark text-hover-primary
                      fw-bold
                      w-375px
                      min-w-200px
                    "
                    >{{ timeline.status_detail }}</a
                  >
                  <!--end::Title-->

                  <!--begin::Progress-->
                  <div class="min-w-50px pe-2">
                    <span class="badge bg-danger">{{ timeline.status }}</span>
                  </div>
                  <!--end::Progress-->
                </div>
                <!--end::Record-->
              </div>
              <!--end::Timeline details-->
            </div>
            <!--end::Timeline content-->
          </div>
          <!--end::Timeline item-->

          <!--begin::Timeline item-->
          <div class="timeline-item" v-else>
            <!--begin::Timeline line-->
            <div class="timeline-line w-40px"></div>
            <!--end::Timeline line-->
            <!--begin::Timeline icon-->
            <div class="timeline-icon symbol symbol-circle symbol-40px">
              <div class="symbol-label bg-light">
                <!--begin::Svg Icon | path: icons/duotone/Communication/Thumbtack.svg-->
                <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                  <i style="font-size: 20px" class="bi bi-pin-angle-fill"></i>
                </span>
                <!--end::Svg Icon-->
              </div>
            </div>
            <!--end::Timeline icon-->
            <!--begin::Timeline content-->
            <div class="timeline-content mb-10 mt-n2">
              <!--begin::Timeline heading-->
              <div class="overflow-auto pe-3">
                <!--begin::Title-->

                <div class="fs-5 fw-bold mb-2">
                  {{ timeline.status }}
                </div>
                <!--end::Title-->
                <!--begin::Description-->
                <div class="d-flex align-items-center mt-1 fs-6">
                  <!--begin::Info-->
                  <div class="text-muted me-2 fs-7">
                    {{ dateThai(timeline.created_at) }} โดย :
                    {{ timeline.admin }}
                  </div>
                  <!--end::Info-->
                  <!--begin::User-->
                  <div
                    class="symbol symbol-circle symbol-25px"
                    data-bs-toggle="tooltip"
                    data-bs-boundary="window"
                    data-bs-placement="top"
                    title="Alan Nilson"
                  ></div>
                  <!--end::User-->
                </div>
                <!--end::Description-->
              </div>
              <!--end::Timeline heading-->
              <!--begin::Timeline details-->
              <div class="overflow-auto pb-5">
                <!--begin::Record-->
                <div
                  class="
                    d-flex
                    align-items-center
                    border border-dashed border-gray-300
                    rounded
                    min-w-750px
                    px-7
                    py-3
                    mb-5
                  "
                >
                  <!--begin::Title-->
                  <a
                    class="
                      fs-6
                      text-dark text-hover-primary
                      fw-bold
                      w-375px
                      min-w-200px
                    "
                  >
                    {{ timeline.status_detail }}
                  </a>

                  <!--end::Title-->

                  <!--begin::Progress-->
                  <div class="min-w-50px pe-2">
                    <span
                      class="badge bg-warning text-dark"
                      v-if="timeline.status === 'รับคำร้อง'"
                    >
                      {{ timeline.status }}</span
                    >
                    <span
                      class="badge bg-primary"
                      v-if="timeline.status === 'ดำเนินการ'"
                    >
                      {{ timeline.status }}</span
                    >

                    <span
                      class="badge bg-success"
                      v-if="timeline.status === 'เสร็จสิ้น'"
                    >
                      {{ timeline.status }}</span
                    >
                  </div>
                  <!--end::Progress-->
                </div>
                <!--end::Record-->
                <div
                  class="
                    d-flex
                    align-items-center
                    border border-dashed border-gray-300
                    rounded
                    min-w-750px
                    px-7
                    py-3
                    mb-5
                  "
                  v-if="
                    timeline.status === 'เสร็จสิ้น' &&
                    appeals.image_appeal.length > 0
                  "
                >
                  <!--begin::Row-->
                  <div class="row g-10 w-750px">
                    <!--begin::Col-->
                    <div
                      class="col-4"
                      v-for="(image, key) in appeals.image_appeal"
                      :key="key"
                    >
                      <!--begin::Overlay-->

                      <!--begin::Image-->
                      <a @click="show(key)" class="d-block">
                        <!--begin::Image-->
                        <div
                          class="
                            overlay-wrapper
                            bgi-no-repeat bgi-position-center bgi-size-cover
                            card-rounded
                            min-h-175px
                          "
                          style="cursor: pointer"
                          :style="{ backgroundImage: 'url(' + image.src + ')' }"
                        ></div>
                        <!--end::Image-->
                      </a>

                      <!--end::Image-->
                    </div>

                    <vue-easy-lightbox
                      scrollDisabled
                      escDisabled
                      moveDisabled
                      :visible="visible"
                      :imgs="appeals.image_appeal"
                      :index="index"
                      @hide="handleHide"
                    ></vue-easy-lightbox>

                    <!--end::Col-->
                  </div>
                  <!--begin::Row-->
                </div>
              </div>
              <!--end::Timeline details-->
            </div>
            <!--end::Timeline content-->
          </div>
          <!--end::Timeline item-->
        </template>
      </div>
      <!--end::Timeline-->
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import { defineComponent, ref } from "vue";
import moment from "moment";
export default defineComponent({
  name: "appeal-show-manage",
  components: { VueEasyLightbox },
  props: ["appeals"],
  setup() {
    const visible = ref(false);
    const index = ref(0);
    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    function handleHide() {
      visible.value = false;
    }

    function show(key) {
      index.value = key;
      visible.value = true;
    }

    return {
      dateThai,
      visible,
      handleHide,
      index,
      show,
    };
  },
});
</script>

<style scoped>
.vel-img-modal.vel-modal {
  width: 100%;
}
</style>
